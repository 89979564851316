/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import React from "react";
// import Ellipse from '../../../assets/Components/Home/AboutOurCompany/Ellipse 1.png';
import { useNavigate } from "react-router";
import aboutOurCompanyLeftBackground from "../../../assets/Components/Home/AboutOurCompany/aboutOurCompanyLeftBackground.png";
import img1 from "../../../assets/Components/Home/AboutOurCompany/img1.webp";
import img2 from "../../../assets/Components/Home/AboutOurCompany/new-img.png";
import satisfiedClientsImage from "../../../assets/Components/Home/AboutOurCompany/satisfiedClientsImage.webp";
import {
  AboutOurCompanyCardData,
  heroFooterSectionCardData,
} from "../../../store/store";
import HeroFooterSectionCard from "../Hero/HeroFooterSectionCard/HeroFooterSectionCard";
import MiniReUseableTitle from "../MiniReUseableTitle/MiniReUseableTitle";
import "./AboutOurCompany.css";
import AboutOurCompanyCard from "./AboutOurCompanyCard";

function AboutOurCompany() {
  const navigate = useNavigate();
  return (
    <div className="relative">
      {/* <div className="aboutOurCompanyLeftBackground" /> */}

      {/* FooterSectionCard */}
      <div className="absolute top-0 left-0 z[-1]">
        <img
          src={aboutOurCompanyLeftBackground}
          alt=""
          className="max-h-[907px] h-full"
        />
      </div>
      {/* <div className="relative">
        <div className="absolute top-[-90vh] right-0 z[-2] overflow-y-hidden"><img src={Ellipse} alt="" className="max-h-[907px] max-w-[440px]" /></div>
      </div> */}

      <div className="container relative top-[-80px]">
        <div className="grid grid-flow-row lg:grid-cols-3 md:grid-cols-2 gap-5">
          {heroFooterSectionCardData.map((cardDetails) => (
            <HeroFooterSectionCard
              key={cardDetails.id}
              cardDetails={cardDetails}
            />
          ))}
        </div>
      </div>

      <div className="container grid grid-flow-row md:grid-cols-2 gap-[50px] mt-[10px]">
        <div>
          <div className="relative">
            <div className="max-h-[520px] max-w-[382px]">
              <img src={img1} alt="" className="object-cover" />
            </div>
            <div className="max-h-[419px] max-w-[261px] absolute bottom-0 right-[40px] hidden sm:block">
              <img src={img2} alt="" className="object-cover" />
            </div>
          </div>

          <div className="flex justify-center relative top-[-35px]">
            <div className="inline-block">
              <div className="flex p-[15px] satisfiedClientsContainer gap-3">
                <div>
                  <img
                    src={satisfiedClientsImage}
                    alt=""
                    className="h-[52px]"
                  />
                </div>
                <div>
                  <div className="satisfiedClientsTitle">3600+</div>
                  <div className="satisfiedClientsContent">
                    Satisfied Clients
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="z-[1] mx-2 sm:mx-0">
          <MiniReUseableTitle title="About Our Company" color="#195CA5" />

          <div className="sectionTitle aboutOurCompanyRightSectionMargin">
            About Our Company
          </div>

          <div className="sectionContent  aboutOurCompanyRightSectionMargin">
            SmartMatrix relies on seasoned professionals in Communications and
            IT, boasting vast experience and global exposure. Our team comprises
            young, qualified business graduates and engineers from prestigious
            universities worldwide, forming the backbone of our expertise.
          </div>

          {AboutOurCompanyCardData.map((cardDetails) => (
            <AboutOurCompanyCard
              key={cardDetails.id}
              cardDetails={cardDetails}
            />
          ))}

          <div>
            <button
              type="button"
              className="mt-8 buttonPrimary"
              onClick={() => navigate("/about")}
            >
              DISCOVER MORE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutOurCompany;
